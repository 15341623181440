.banner {
  position: relative;
  height: 100vh;
  font-family: "Times New Roman", Times, serif;
}

.contentStyle {
  height: 100vh;
  width: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.header {
  position: absolute;
  z-index: 10;
  margin-left: 2%;
  margin-top: 15px;
  width: 96%;
}

.headerBottom {
  padding-bottom: 10px;
  margin-bottom: 30vh;
}

.sizeCenter {
  color: white;
  font-size: calc(12px + 1.8vw);
  white-space: nowrap;
}
