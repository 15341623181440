.footer{
position: absolute;
background-color: #4C4C4C;
height:100px;
width:100%;
margin-top: 28vh;
}

.footerText{
    font-size: small;
    text-decoration: none;
    color: #CCC;
}