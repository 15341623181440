.fontSizeStyle {
  font-size: calc(10px + 0.8vw + 0.8vh);
  color: white;
  margin-left: 10px;
  white-space: nowrap;
  letter-spacing: calc(0.15vw + 0.15vh);
}

.iconStyles {
  font-size: 30px;
  color: white;
  margin-left: 20px;
}
