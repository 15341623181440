.sizeBody {
  font-family: "Times New Roman", Times, serif;
}

.fieldOne {
  height: 10vh;
}

.fieldTwo {
  font-size: calc(10.5px + 0.58vw + 0.215vh);
}

.underlyingLegalService {
  font-size: calc(7vw + 1.5vh);
  color: #e2e2e2;
  margin-top: calc(30px - 3vw - 3vh);
  margin-bottom: calc(30px - 2.2vw - 2.8vh);
  white-space: nowrap;
}

.layer1997 {
  position: relative;
  margin-top: 3vh;
}

.underlying1997 {
  position: absolute;
  font-size: calc(50px + 13vw);
  z-index: -1;
  color: #e2e2e2;
  margin-left: -4vw;
}

.upperFile {
  z-index: 10;
}

.left1997Found {
  margin-top: calc(3vh + 10vw - 28px);
  margin-left: 17vw;
  margin-bottom: 1vh;
  white-space: nowarp;
}

.signatureRight {
  text-align: end;
  font-size: 22px;
}

.legalTop {
  margin-top: 12vh;
}

.textFound {
  font-size: calc(12px + 1.2vw);
}

.text1997 {
  font-size: calc(12px + 2.2vw);
}
